// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NosFantomes-module--CarrouselWrapper2--OufjB";
export var DescriptionWrapper = "NosFantomes-module--DescriptionWrapper--XtK7Y";
export var DownloaderWrapper = "NosFantomes-module--DownloaderWrapper--vEWAd";
export var EventWrapper = "NosFantomes-module--EventWrapper--rDe8+";
export var ImageWrapper = "NosFantomes-module--ImageWrapper--H-R+C";
export var ImagesWrapper = "NosFantomes-module--ImagesWrapper--GHqxv";
export var MobileTile = "NosFantomes-module--MobileTile--uMuqT";
export var PdpWrapper = "NosFantomes-module--PdpWrapper--BBLMB";
export var PhotosWrapper = "NosFantomes-module--PhotosWrapper--G3lRq";
export var TitleWrapper = "NosFantomes-module--TitleWrapper--6ZlWP";
export var Wrapper = "NosFantomes-module--Wrapper--YFpBe";